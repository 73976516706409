import {useEffect, useState} from "react"
import {collection, doc, getDoc, getDocs} from "firebase/firestore"
import {db} from "../firebase/Firebase"
import Loading from "../Loading"

const Drivers = () => {
    const [pointsSystem, setPointsSystem] = useState(null)
    const [pointsSystemId, setPointsSystemId] = useState("1950")
    const [showYear, setShowYear] = useState("2022")
    const [yearResults, setYearResults] = useState([])
    const [allScores, setAllScores] = useState([])
    const [allDrivers, setAllDrivers] = useState([])
    const [championshipPoints, setChampionshipPoints] = useState([])
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)

    // load points system
    useEffect(() => {
        const getData = async () => {
            setStatus('loading points system')
            setLoading(true)
            setAllScores([])
            setChampionshipPoints([])
            let d = await getDoc(doc(db, "point_systems", "drivers", "systems", String(pointsSystemId)))
            setPointsSystem(d.data())
            setStatus(null)
            setLoading(false)
        }
        if (pointsSystemId) getData()
    }, [pointsSystemId])

    // load race results
    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            setAllScores([])
            setChampionshipPoints([])
            let toYearResults = []
            let toAllDrivers = []
            let races_snap = await getDocs(collection(db, "years", String(showYear), "races"))
            let r = 1
            for (let race_doc of races_snap.docs) {
                let p = 0
                toYearResults.push({results:[], ...race_doc.data()})
                let positions_snap = await getDocs(collection(db, "years", String(showYear), "races", race_doc.id, "raceresults"))
                for (let position_doc of positions_snap.docs) {
                    let i = toYearResults.findIndex(o => o.round === race_doc.data().round)
                    toYearResults[i].results.push({position:parseInt(position_doc.id, 10), ...position_doc.data()})
                    let d = toAllDrivers.find(o => o.id === position_doc.data().driver)
                    if (!d) {
                        let driver_doc = await getDoc(doc(db, "drivers", position_doc.data().driver))
                        toAllDrivers.push(driver_doc.data())
                    }
                    p++
                    setStatus(`loading data for race ${r}, finishing position ${p}`)
                }
                r++
            }
            setYearResults(toYearResults)
            setAllDrivers(toAllDrivers)
            setLoading(false)
            setStatus(null)
        }
        if (showYear) getData()
    }, [showYear])

    // calculate points
    useEffect(() => {
        if (pointsSystem && yearResults && allDrivers.length > 0) {
            setLoading(true)
            let scores = []
            let r = 1
            for (let race of yearResults) {
                let results = race.results
                let p = 1
                for (let position of results) {
                    setStatus(`calculating points from race ${r}, finishing position ${p}`)
                    let points = 0
                    for (let systemposition of pointsSystem.positions) {
                        if (systemposition.position === parseInt(position.position, 10)) points = systemposition.points * race.multiplier
                    }

                    let driver = allDrivers.find(o => o.id === position.driver)
                    scores.push({
                        driverid:position.driver,
                        driver:driver.fullname,
                        round:race.round,
                        race:race.name,
                        points:Math.round(points * 10) / 10
                    })
                    p++
                }
                r++
            }
            setAllScores(scores)
            setLoading(false)
            setStatus(null)
        }
    }, [pointsSystem, yearResults, allDrivers])

    // calculate championship points
    useEffect(() => {
        if (pointsSystem && allScores && allScores.length > 0 && allDrivers.length > 0) {
            setLoading(true)
            let championship_points = []
            let threshold
            if (pointsSystem.best_count.all) {
                threshold = pointsSystem.best_count.all
            }

            for (let driver of allDrivers) {
                let total_points = 0
                let driver_scores = allScores.filter(o => o.driverid === driver.id)
                driver_scores.sort((a, b) => b.points - a.points)
                for (let i = 0; i < threshold; i++) {
                    if (driver_scores[i]) {
                        total_points += driver_scores[i].points
                    }
                }
                championship_points.push({
                    driverid:driver.id,
                    driver:driver.fullname,
                    points:total_points
                })
            }
            championship_points.sort((a, b) => b.points - a.points)
            setChampionshipPoints(championship_points)
            setLoading(false)
            setStatus("finished")
            setTimeout(() => {
                setStatus(null)
            }, 2000)
        }
    }, [pointsSystem, allScores, allDrivers])

    const range = (start, stop, step) => Array.from({length:(stop - start) / step + 1}, (_, i) => start + (i * step))

    return <div style={{textAlign:'left'}}>
        championship year: <select onChange={(e) => setShowYear(e.target.value)} defaultValue={showYear}>
        {range(1950, 2022, 1).map(i => <option key={i}>{i}</option>)}
    </select>&nbsp;
        points system:
        <select onChange={(e) => setPointsSystemId(e.target.value)} defaultValue={pointsSystemId}>
            <option value="1950">1950-1953</option>
            <option value="1991">1991-2002</option>
            <option value="2003">2003-2009</option>
            <option value="2010">2010-2018</option>
            <option value="2022">2022</option>
        </select><br/><br/>
        <div style={{backgroundColor:'lightgrey'}}>{loading && <Loading/>}
            &nbsp;
            {status || null}</div>
        {!loading && championshipPoints && championshipPoints.length > 0 &&
            <ol>
                {championshipPoints.map(p =>
                    <li key={p.driverid}>{p.driver} ({p.points})</li>
                )}
            </ol>
        }
        <hr/>
        (fastest laps not yet included)
    </div>
}

export default Drivers